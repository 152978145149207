import Head from 'next/head';
import { forwardRef, ReactNode } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';
import { DashboardPermissionsType } from 'src/auth/types/permissions';
import PermissionBasedGuard from 'src/auth/PermissionBasedGuard';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
  permissions?: DashboardPermissionsType[];
}

const Page = forwardRef<HTMLDivElement, Props>(
  ({ children, title = '', permissions, meta, ...other }, ref) => (
    <>
      <Head>
        <title>{title}</title>
        {meta}
      </Head>
      {permissions && (
        <PermissionBasedGuard permissions={permissions} hasContent>
          <Box ref={ref} {...other}>
            {children}
          </Box>
        </PermissionBasedGuard>
      )}
      {!permissions && (
        <Box ref={ref} {...other}>
          {children}
        </Box>
      )}
    </>
  )
);

Page.displayName = 'Page';

export default Page;
