import { m } from 'framer-motion';
import { Container, Stack, Typography } from '@mui/material';
import { usePropelAuthContext } from 'src/auth/useAuthContext';
import { MotionContainer, varBounce } from '../components/animate';
import { ForbiddenIllustration } from '../assets';
import { FullPermissionsType } from './types/permissions';

// ----------------------------------------------------------------------

type Props = {
  hasContent?: boolean;
  permissions?: FullPermissionsType[];
  children: React.ReactNode;
};

export default function PermissionBasedGuard({ hasContent, permissions, children }: Props) {
  const { permissions: userPermissions } = usePropelAuthContext();

  const hasAccess =
    typeof permissions !== 'undefined' &&
    userPermissions &&
    userPermissions.some((permission) => permissions.includes(permission));

  if (!hasAccess) {
    return hasContent ? (
      <Container
        component={MotionContainer}
        sx={{
          textAlign: 'center',
        }}
      >
        <Stack justifyContent="center" alignItems="center" sx={{ height: '75vh' }}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              Permission Denied
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              You do not have permission to access this page
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
          </m.div>
        </Stack>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}
